import React, { FC, useContext, useState } from 'react';
import './styled.css';
import InputField from '../InputField';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import InputWhite from '../../atoms/InputWhite/InputWhite';
import { Form, Formik } from 'formik';
import validationSchema from './validation';
import Button from '../../atoms/buttons/SubmitButton/SubmitButton';
import { MemberFormProps } from './type';
import SimpleSelectField from '../SelectField';
import { KeycardType, NoKeyCardType } from '../../../constans/keycardType';
import { getDayOption, monthOptions, yearOptions } from './utils';
import i18n from '../../../i18n';
import Camera from '../../../icons/camera.svg';

import { AppContext } from '../../../AppContext';
import QrCodeDetector from '../QrCodeDetector';
import getKeyCardImage from '../../../utils/getKeyCardImage';

const MemberForm: FC<MemberFormProps> = ({
  isAddPerson,
  isEditMember = false,
  setBirthDate,
  onSubmit,
  initialValues = {},
  isDateNotInRange = false,
  newKeycardCost = 0,
}) => {
  const [detectorOpen, setDetectorOpen] = useState(false);
  const { chosenMainCategoryState } = useContext(AppContext);
  const location = useLocation();
  const { t } = useTranslation();

  const [chosenMainCategory] = chosenMainCategoryState;

  const selectOptionsKeyCardType: { label: string; value: KeycardType }[] = [
    { label: t('user.noKeycardinfo'), value: NoKeyCardType },
    { label: 'LAAX Keycard', value: 'AXESS_DESTINATION' },
    { label: 'Axess Keycard', value: 'AXESS' },
    { label: 'SkiData Keycard', value: 'SKIDATA' },
  ];

  return (
    <Formik
      {...{
        initialValues: {
          firstName: '',
          lastName: null,
          dayBirth: '',
          monthBirth: '',
          yearBirth: '',
          keyCardType: selectOptionsKeyCardType[0].value,
          keyCardDisplayNumber: '',
          ...initialValues,
        },
        onSubmit,
        validationSchema,
      }}
    >
      {({
        values,
        isSubmitting,
        setFieldValue,
        errors,
        setFieldTouched,
        submitCount,
      }) => {
        const noKeyCardSelected = values.keyCardType === NoKeyCardType;
        let dayOptions = getDayOption(values.monthBirth, values.yearBirth);
        let birthValuesSelected =
          values.yearBirth && values.monthBirth && values.dayBirth;
        const showDateRangeError =
          isDateNotInRange &&
          birthValuesSelected &&
          !errors.monthBirth &&
          !errors.yearBirth &&
          !errors.dayBirth;
        let disableButton =
          new Date() <=
          new Date(values.yearBirth, values.monthBirth, values.dayBirth);
        const setKeyCarType = (keyCardType: KeycardType) => {
          if (keyCardType !== values.keyCardType) {
            setFieldValue('keyCardType', keyCardType);
            setFieldTouched('keyCardDisplayNumber', false);
            setFieldValue('keyCardDisplayNumber', '');
          }
        };
        const YearSelect = (
          <SimpleSelectField
            name={'yearBirth'}
            options={yearOptions}
            variant={'small'}
            placeholder={t('commons.yyyy')}
            onChange={(year) => {
              if (setBirthDate && birthValuesSelected)
                setBirthDate(
                  new Date(year, values.monthBirth, values.dayBirth)
                );
            }}
          />
        );
        const DaySelect = (
          <SimpleSelectField
            name={'dayBirth'}
            options={dayOptions}
            variant={'small'}
            placeholder={t('commons.dd')}
            onChange={(day) => {
              if (setBirthDate && birthValuesSelected)
                setBirthDate(
                  new Date(values.yearBirth, values.monthBirth, day)
                );
            }}
          />
        );
        return (
          <Form>
            <div>
              <InputField label={t('commons.name')} name={'firstName'} />
              <InputField label={t('commons.surname')} name={'lastName'} />
              <div className={'labelBirtDate'}>{t('commons.birthDate')}</div>
              <div className={'dateBirth-container'}>
                {i18n?.language === 'de' ? DaySelect : YearSelect}
                <SimpleSelectField
                  name={'monthBirth'}
                  options={monthOptions}
                  variant={'small'}
                  placeholder={t('commons.mm')}
                  onChange={(month) => {
                    if (setBirthDate && birthValuesSelected)
                      setBirthDate(
                        new Date(values.yearBirth, month, values.dayBirth)
                      );
                  }}
                />
                {i18n?.language === 'de' ? YearSelect : DaySelect}
              </div>
              {showDateRangeError && (
                <span style={{ color: '#c40404' }}>
                  {t('checkoutView.chooseProperDateTicketType')}
                </span>
              )}
              {location.pathname === '/user-panel' && !isAddPerson ? (
                <InputWhite
                  label={t('commons.phone')}
                  type='text'
                  name='phone'
                />
              ) : null}
              <h3 style={{ margin: '12px 0 ' }} className='keyCardTitle'>
                {t('commons.addKeycard')}
              </h3>
              {selectOptionsKeyCardType.map((option, index) => (
                <div
                  key={option.value + '--' + index}
                  className={'keyCardInfo'}
                  style={{
                    border: `2px solid ${
                      values.keyCardType === option.value
                        ? '#af272f'
                        : 'rgb(240, 240, 240)'
                    }`,
                  }}
                  onClick={() => setKeyCarType(option.value)}
                >
                  <div className={'ratioCircle'} />
                  {values.keyCardType === option.value && (
                    <div className={'ratioDot'} />
                  )}

                  <div
                    style={{
                      width: option.value === NoKeyCardType ? '90%' : '50%',
                      paddingLeft: '20px',
                    }}
                  >
                    {option.value !== NoKeyCardType && (
                      <p style={{ margin: 2 }}>{option.label}</p>
                    )}
                    <p className='keyCardInfoText' style={{ margin: 2 }}>
                      {t(`user.${option.value}info`)}
                    </p>
                  </div>
                  {option.value !== NoKeyCardType && (
                    <img
                      className={'keyCardImage'}
                      src={getKeyCardImage(option.value)}
                      alt={'keycard'}
                    />
                  )}
                  {option.value !== NoKeyCardType &&
                    values.keyCardType === option.value && (
                      <div
                        style={{
                          position: 'relative',
                          padding: '10px 20px',
                          width:
                            option.value === 'AXESS_DESTINATION'
                              ? '220px'
                              : '260px',
                        }}
                      >
                        <InputField
                          label={t('commons.addKeycard')}
                          name={'keyCardDisplayNumber'}
                          style={{
                            paddingRight:
                              option.value === 'AXESS_DESTINATION' ? '40px' : 0,
                          }}
                        />
                        {option.value === 'AXESS_DESTINATION' && (
                          <div
                            onClick={() => setDetectorOpen(true)}
                            style={{
                              position: 'absolute',
                              right: '-10px',
                              top: '40%',
                            }}
                          >
                            <img alt={'Camera'} src={Camera} />
                          </div>
                        )}
                      </div>
                    )}
                </div>
              ))}

              {values.keyCardType === NoKeyCardType && (
                <p className='keyCardInfoText'>
                  {t('checkoutView.noProblemAddMemberDescription')}
                </p>
              )}
              <div className='addMemberButtonContainer'>
                <Button
                  className='addMemberButton'
                  type='submit'
                  disabled={
                    showDateRangeError ||
                    disableButton ||
                    (Object.keys(errors).filter(
                      (e) =>
                        e !== 'keyCardDisplayNumber' ||
                        values.keyCardType !== NoKeyCardType
                    ).length > 0 &&
                      submitCount > 0) ||
                    isSubmitting
                  }
                >
                  {t('commons.confirm')}
                </Button>
              </div>
              {newKeycardCost > 0 &&
                noKeyCardSelected &&
                chosenMainCategory?.indexOf('snowboard') !== -1 && (
                  <p
                    className='tileContainerText'
                    style={{ marginTop: '16px', marginLeft: '12px' }}
                  >
                    {t('checkoutView.winterTicketFee', {
                      value: newKeycardCost,
                    })}
                  </p>
                )}
              <QrCodeDetector
                isOpen={detectorOpen}
                handleClose={() => setDetectorOpen(false)}
                onDetect={(code) => {
                  setFieldValue('keyCardDisplayNumber', code);
                  setDetectorOpen(false);
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MemberForm;
