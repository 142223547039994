import keyCardAxess from '../assets/keycard-axess-back@4x.png';
import keyCardLaax from '../assets/keycard-laax-back@4x.png';
import keyCardSkidata from '../assets/keycard-skidata-back@4x.png';
import keyCardSwisspass from '../assets/keycard-swisspass-back@4x.png';

const getKeyCardImage = (keyCardType: string): string => {
  if (keyCardType === 'AXESS') return keyCardAxess;
  else if (keyCardType === 'AXESS_DESTINATION') return keyCardLaax;
  else if (keyCardType === 'SKIDATA') return keyCardSkidata;
  else return keyCardSwisspass;
};

export default getKeyCardImage;
