import { gql } from '@apollo/client';

export const CREATE_PARTY_MEMBER = gql`
  mutation CPM($partyMember: CreatePartyMemberInput!) {
    createPartyMember(input: $partyMember) {
      partyMembers {
        id
        firstName
        keycard {
          displayNumber
          type
        }
      }
    }
  }
`;

export const UPDATE_PARTY_MEMBER = gql`
  mutation UPM($partyMember: UpdatePartyMemberInput!) {
    updatePartyMember(input: $partyMember) {
      partyMembers {
        id
        firstName
      }
    }
  }
`;

export const FETCH_PARTY_MEMBERS = gql`
  query {
    guest {
      partyMembers {
        id
        firstName
        lastName
        dateOfBirth
        keycard {
          displayNumber
          type
        }
      }
    }
  }
`;

export const REMOVE_PARTY_MEMBER = gql`
  mutation CPM($partyMemberID: RemovePartyMemberInput!) {
    removePartyMember(input: $partyMemberID) {
      partyMembers {
        id
        firstName
        lastName
        dateOfBirth
        keycard {
          displayNumber
          type
        }
      }
    }
  }
`;

export const FETCH_GROUP_MEMBERS = gql`
  query {
    guest {
      partyMembers {
        id
        firstName
        lastName
        dateOfBirth
        keycard {
          displayNumber
          type
        }
      }
    }
  }
`;
