import { FC } from 'react';

import { IButton } from '../../../../interfaces/AtomsRelatedInterfaces';

import './PayButton.css';

const Button: FC<IButton> = ({ children, className, disabled, ...rest }) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={`${className} ${disabled ? 'pay-button-disabled' : ''}`}
    >
      {children}
    </button>
  );
};

export default Button;
